import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout bgImg="404.jpg">
    <Seo title="404: Not found" />
  </Layout>
)

export default NotFoundPage
